.footer {
    width: 100%;
    padding: 1rem 0;
    background-color: rgba(19, 19, 19, 0.8);
    display: flex;
    justify-content: center;
}

.footer-container {
    max-width: 1140;
}

.footer-container p {
    margin-left: 5.9rem;
    font-size: .7rem;
}

.social {
    margin: .8rem 9rem;
}



@media screen and (max-width: 640px) {

    .footer-container {
        grid-template-columns: 1fr;
        width: 100%;
    }

    .social {
        display: flex;
    }

    .footer-container h4 {
        margin-left: 1rem;
    }
}